import React from "react"
import H1 from "../../components/H1"
import * as styles from "./styles.module.css"

const One = () => {
  return (
    <section className={styles.one}>
      <H1 text="Contact Us" addTopMargin={true}></H1>

      <div className={styles.grid}>
        <div>
          <span>
            Have a question/comment or need assistance? Please fill out the
            contact form below and we will answer you promptly.
          </span>
        </div>
      </div>
    </section>
  )
}

export default One
