import React from "react"
import { isFieldError } from "../../utils/helpers"
import * as styles from "./styles.module.css"

const Textarea = ({
  name,
  label,
  reqd,
  value = "",
  autocomplete = name,
  update,
  errors,
  note = "",
}) => {
  return (
    <div>
      <label htmlFor={name}>
        {`${label}`} {reqd ? <span className="error">*</span> : null}
      </label>
      <textarea
        name={name}
        id={name}
        defaultValue={value}
        autoComplete={autocomplete}
        onChange={e => update(name, e.target.value)}
        className={
          isFieldError(errors, name)
            ? `${styles.textarea} ${styles.error}`
            : `${styles.textarea}`
        }
      />
      <small className={note ? "note" : "hidden"}>{note}</small>
    </div>
  )
}
export default Textarea
