import React, { useState, useRef } from "react"
import { API_URL } from "../../utils/helpers"
import Select from "../../components/Select"
import Input from "../../components/Input"
import Textarea from "../../components/Textarea"
import Checkbox from "../../components/Checkbox"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import Alert from "../../components/Alert"
import * as styles from "./styles.module.css"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Two = () => {
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})
  const [alert, setAlert] = useState({})

  const formElement = useRef(null)

  const handleUpdate = (name, value) => {
    if (name === "optin") {
      setNames(names => ({ ...names, [name]: !value }))
    } else {
      setNames(names => ({ ...names, [name]: value ? value : "" }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/contact`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      if (key === "optin") {
        data.append(key, value ? "1" : "0")
      } else {
        data.append(key, value)
      }
    })

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        setAlert({
          type: "success",
          text: json.text,
        })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <section className={styles.two}>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <form
          method="post"
          action="/"
          onSubmit={e => handleSubmit(e)}
          ref={formElement}
        >
          <div className="two">
            <Select
              name="subject"
              label="Subject"
              reqd={true}
              autocomplete="off"
              options={[
                {
                  value: "",
                  name: "—",
                },
                {
                  value: "General Question",
                  name: "General Question",
                },
                {
                  value: "Technical Question",
                  name: "Technical Question",
                },
                {
                  value: "Account Question",
                  name: "Account Question",
                },
                {
                  value: "Other",
                  name: "Other",
                },
              ]}
              update={handleUpdate}
              errors={errors}
            />

            <div className="empty" />
          </div>

          <div className="two">
            <Input
              name="fname"
              label="First Name"
              reqd={true}
              autocomplete="given-name"
              update={handleUpdate}
              errors={errors}
            />

            <Input
              name="lname"
              label="Last Name"
              reqd={true}
              autocomplete="family-name"
              update={handleUpdate}
              errors={errors}
            />
          </div>

          <div className="two">
            <Input
              name="email"
              label="Email Address"
              reqd={true}
              autocomplete="email"
              update={handleUpdate}
              errors={errors}
            />

            <Input
              name="phone"
              label="Phone"
              reqd={false}
              autocomplete="tel"
              update={handleUpdate}
              errors={errors}
            />
          </div>

          <div className="one">
            <Textarea
              name="comments"
              label="Question/Comment"
              reqd={true}
              autocomplete="off"
              update={handleUpdate}
              errors={errors}
            />
          </div>

          <div className="one">
            <Checkbox
              name="optin"
              label="Check to be added to our email news list"
              reqd={false}
              click={handleUpdate}
              checked={names.optin || false}
            />
          </div>

          <div className="msgSubmit">
            {msg.type && <Msg data={msg} />}

            <div className={msg.type === "working" ? "hidden" : ""}>
              <Submit name="Submit Inquiry" icon={faChevronCircleRight} />
            </div>
          </div>
        </form>
      )}
    </section>
  )
}
export default Two
