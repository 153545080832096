import React from "react"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
  faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons"

const Alert = ({ data }) => {
  const type = data.type
  const icon =
    type === "success"
      ? faCheckCircle
      : type === "notice"
      ? faExclamationCircle
      : type === "working"
      ? faSpinnerThird
      : faTimesCircle

  return (
    <div className={`${styles.container} ${styles[type]}`}>
      <FontAwesomeIcon icon={icon} size="2x" spin={type === "working"} />
      <strong>{data.text}</strong>
    </div>
  )
}
export default Alert
