import React, { useState, Fragment } from "react"
import { Link } from "gatsby"
import { getValFromLS } from "../../utils/helpers"
import logo from "../../img/logo-tm.svg"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/pro-light-svg-icons"

const Header = () => {
  const [toggle, setToggle] = useState(false)
  const items = [
    {
      id: "recap",
      name: "Weekly Recap",
      btn: false,
    },
    {
      id: "blog",
      name: "Blog",
      btn: false,
    },
    {
      id: "faqs",
      name: "FAQs",
      btn: false,
    },
    {
      id: "contact",
      name: "Contact Us",
      btn: false,
    },
    {
      id: "signin",
      name: "Sign In",
      btn: true,
      show: getValFromLS("etfsrToken", true) ? false : true,
    },
    {
      id: "signup",
      name: "Build My Portfolio Now",
      btn: true,
      show: getValFromLS("etfsrToken", true) ? false : true,
    },
    {
      id: "sub",
      name: "Subscriber’s Area",
      btn: true,
      show: getValFromLS("etfsrToken", true) ? true : false,
    },
  ]

  return (
    <Fragment>
      <div className={styles.sticky}>
        <div className={styles.containerDesktop}>
          <div className={styles.left}>
            <Link to="/" className={styles.logo}>
              <img src={logo} alt="ETF Stock Report Logo" />
            </Link>

            {items.map(item => {
              return !item.btn ? (
                <Link
                  to={`/${item.id}`}
                  key={item.id}
                  activeClassName="selected"
                >
                  {item.name}
                </Link>
              ) : null
            })}
          </div>

          <div className={styles.right}>
            {items.map(item => {
              return item.btn && item.show ? (
                <Link
                  to={`/${item.id}`}
                  className={item.id === "signup" ? `btn green` : `btn`}
                  key={item.id}
                >
                  {item.name}
                </Link>
              ) : null
            })}
          </div>
        </div>
      </div>

      <div className={styles.sticky}>
        <div className={styles.containerMobile}>
          <div className={styles.left}>
            <Link to="/" className={styles.logo}>
              <img src={logo} alt="ETF Stock Report Logo" />
            </Link>
          </div>

          <div className={styles.right}>
            <button
              type="button"
              onClick={() => setToggle(!toggle)}
              className="icon"
            >
              <FontAwesomeIcon icon={faBars} size="2x" />
            </button>
          </div>
        </div>

        <div
          className={
            toggle
              ? styles.containerMobileMenu
              : `${styles.containerMobileMenu} ${styles.containerMobileMenuHidden}`
          }
        >
          <button
            type="button"
            onClick={() => setToggle(!toggle)}
            className={`icon ${styles.close}`}
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <Link to={`/`} className={""} key="home">
            Home
          </Link>

          {items.map(item => {
            return !item.btn || item.show ? (
              <Link
                to={`/${item.id}`}
                className={item.btn ? `btn ${styles.mobile}` : ""}
                key={item.id}
              >
                {item.name}
              </Link>
            ) : null
          })}
        </div>
      </div>
    </Fragment>
  )
}

export default Header
