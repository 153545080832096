// extracted by mini-css-extract-plugin
export var sticky = "styles-module--sticky--1n2Zu";
export var containerDesktop = "styles-module--containerDesktop--4EQ37";
export var containerMobile = "styles-module--containerMobile--2V5QS";
export var left = "styles-module--left--1k_rZ";
export var logo = "styles-module--logo--3i8yT";
export var right = "styles-module--right--1Vxjg";
export var containerMobileMenu = "styles-module--containerMobileMenu--24sXl";
export var containerMobileMenuHidden = "styles-module--containerMobileMenuHidden--jHseJ";
export var close = "styles-module--close--3GX6S";
export var mobile = "styles-module--mobile--1rh-d";