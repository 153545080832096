import React, { Fragment } from "react"
import Helmet from "../components/Helmet"
import Header from "../components/Header"
import One from "../contactPage/One"
import Two from "../contactPage/Two"
import Footer from "../components/Footer"

const ContactPage = () => (
  <Fragment>
    <Helmet
      title="Contact Us About Our Financial Investment Advice Subscription Service"
      descr="Contact ETF Stock Report if you have any questions about our stock picks service, or about your financial advice subscription. ETF Stock Report is located at 177 N. US Hwy 1 Unit #288, Tequesta, FL 33469."
      slug="/contact"
    />
    <Header />
    <One />
    <Two />
    <Footer />
  </Fragment>
)

export default ContactPage
