import React from "react"
import { isFieldError } from "../../utils/helpers"
import * as styles from "./styles.module.css"

const Select = ({
  name,
  label,
  reqd,
  autocomplete = name,
  options,
  update,
  errors,
  value = "",
}) => {
  return (
    <div>
      <label htmlFor={name}>
        {`${label}`} {reqd ? <span className="error">*</span> : null}
      </label>
      <div
        className={
          isFieldError(errors, name)
            ? `${styles.selectContainer} ${styles.error}`
            : `${styles.selectContainer}`
        }
      >
        <select
          name={name}
          id={name}
          autoComplete={autocomplete}
          onChange={e => update(name, e.target.value)}
          className={styles.select}
          defaultValue={value}
        >
          {options.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}
export default Select
